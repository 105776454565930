<div class="vertical-align-wrap">
    <div class="vertical-align-middle auth-main">
        <div class="auth-box container">
            <div class="row row align-items-center">
                <div class="col-md-6">
                    <div class="top">
                        <!-- <img src="./assets/assets/images/vttouch.png" alt="Lucid"> -->
                    </div>
                    <div class="card">
                        <div class="top-part text-center">
                            <div class="tp-img">
                                <a href="https://fambizcommunity.com/"><img src="./assets/assets/images/logo.jpg" alt="logo"></a>
                            </div>
                           <!--<div class="reg-btn">
                                <button type="button" class="btn sgn-up-nw" [routerLink]="['/register']"> Register</button>
                                <button type="button" class="btn pbtn-lite"> Sign In</button>
                            </div>-->
                        </div>
                        <div class="header">
                            <p class="lead">Welcome back! Please login to your account</p>
                        </div>
                        <div class="body">
                            <!-- <h2>Registration</h2> -->
                            <form [formGroup]="loginForm" autocomplete="on">
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <!-- <label>Email <span>*</span> </label> -->
                                            <input class="form-control" type="input" formControlName="email" placeholder="Email" autocomplete="email" />
                                            <span class="text-danger" *ngIf="control.email.dirty && control.email.hasError('required')">
                                                Email Required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <!-- <label>Password <span>*</span></label> -->
                                            <input class="form-control" type="password" formControlName="password" placeholder="Password" />
                                            <span class="text-danger" *ngIf="control.password.dirty && control.password.hasError('required')">
                                                Password Required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 text-left checkb0x-mng">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" value="1" (change)="checkValue($event)" [checked]="remember">
                                            <label class="form-check-label">
                                            Remember Me
                                            </label>
                                        </div>
                                        <!-- <div class="form-check">
                                            <input type="checkbox" class="form-check-input" value="1" formControlName="acceptTerms">
                                            <label class="form-check-label">
                                            Remember Me
                                            </label>
                                        </div> -->
                                        <!-- <span class="text-danger" *ngIf="control.acceptTerms.dirty && control.acceptTerms.hasError('required')">
                                            Please accept terms and conditions.
                                        </span> -->
                                    </div>
                                    <div class="col-md-4 text-right checkb0x-mng ">
                                        <a href="javascript:" (click)="forgotPassword()" class="form-check-label">Forgot password?</a>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-6 text-left checkb0x-mng">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="1" (change)="checkValue($event)" [checked]="remember" ><span>Remember me </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right checkb0x-mng ">
                                        <a href="javascript:" (click)="forgotPassword()" class="form-check-label">Forgot password?</a>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-12 mt-4 sgn-lst-btn">
                                        <!-- <button _ngcontent-rvk-c122="" type="button" class="btn pbtn"> Sign Up</button> -->
                                        <button type="button" class="btn pbtn m-r-15" (click)="submit()" [disabled]="!loginForm.valid">Login
                                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                        <!-- <button type="button" class="btn sgn-up-nw m-r-15" [routerLink]="['/register']"> Register</button> -->
                                        <!-- <ul>
                                            <li><a href="#">Terms of Uses</a></li>
                                            <li class="dont-blue"> <a href="https://fambizcommunity.b-cdn.net/wp-content/uploads/2023/01/Fam-Biz-Privacy-Policy-updated-04042022.pdf" target="_blank" >Privacy Policy</a></li>
                                        </ul> -->
                                    </div>
                                    <div class="col-md-12 mt-1 FamibizCommuty" style="color: #243f5a;">
                                        <i class="fa fa-info-circle"></i>  To register, please visit the <a href="https://fambizcommunity.com/subscription-options/" target="_blank">Fambiz Community page.</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="wlcom-signp">
                        <h2>
                            <span style="color:#fff">Elevate</span> your business	
                        </h2>
                        <p>We unite the family business community to provide a voice, a sense of belonging and an environment for learning and development.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
