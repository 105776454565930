import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingService } from 'src/app/services/training.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-training-add',
  templateUrl: './training-add.component.html',
  styleUrls: ['./training-add.component.css']
})
export class TrainingAddComponent implements OnInit {

  CreateForm: FormGroup;

  isAddMode: boolean;
  id: string;
  changeIcon: boolean = false;
  changeHoverIcon: boolean = false;
  selectedvalue: number=0;
  constructor(private formBuilder: FormBuilder,
    private trainingService: TrainingService,
    private router: Router, private route: ActivatedRoute,
    private toastr: ToastrService,
    private _location: Location) {

    this.CreateForm = this.formBuilder.group({
      id: [],
      title: ['', [Validators.required]],
      training_type: ['', [Validators.required]],
      description: ['', [Validators.required]],
      video_link: [''],
      course_link: [''],
      training_doc: ['']
    })
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    if (!this.isAddMode) {
      this.trainingService
        .getSingleTraining({ 'id': this.id }).subscribe((res: any) => {
          if (res.status) {
            this.CreateForm = this.formBuilder.group({
              id: [],
              title: ['', [Validators.required]],
              training_type: ['', [Validators.required]],
              description: ['', [Validators.required]],
              video_link: [''],
              course_link: [''],
              training_doc: ['']
            })
            this.CreateForm.patchValue(res.data)
            this.selectedvalue = res.data.training_type
          } else {
            this.toastr.error("partner not found", "Error");
          }
        })
    }
  }

  get control() {
    return this.CreateForm.controls;
  }

  submit() {
    if (this.CreateForm.invalid) {
      return;
    }
    if (this.isAddMode) {
      console.log('Create')
      this.createTraining();
    } else {
      console.log('update')
      this.updateTraining();
    }
  }

  createTraining() {
    const formData = new FormData();
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('training_type', this.CreateForm.get('training_type').value);
    formData.append('video_link', this.CreateForm.get('video_link').value);
    formData.append('course_link', this.CreateForm.get('course_link').value);
    formData.append('description', this.CreateForm.get('description').value);
    formData.append('training_doc', this.CreateForm.get('training_doc').value);

    this.trainingService.store(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data add successfully', "Success");
        this.router.navigateByUrl('admin/programme-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  updateTraining() {
    console.log('update2')
    const formData = new FormData();
    formData.append('training_id', this.CreateForm.get('id').value);
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('training_type', this.CreateForm.get('training_type').value);
    formData.append('video_link', this.CreateForm.get('video_link').value);
    formData.append('course_link', this.CreateForm.get('course_link').value);
    formData.append('description', this.CreateForm.get('description').value);

    if (this.changeIcon) {
      console.log('update3')
      formData.append('training_doc', this.CreateForm.get('training_doc').value);
    }

    this.trainingService.update(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data update successfully', "Success");
        this.router.navigateByUrl('admin/programme-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  isFileAdd = false;
  onFileChange(event, isIcon = '') {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
        this.toastr.error("Allow only 20 mb file size", "Error");
        return;
      }
      if (isIcon == 'training_doc') {
        this.changeIcon = true;
        this.control.training_doc.setValue(file);
      } else {

        this.control.training_doc.setValue(file);
      }
    }
  }

  backClicked() {
    this._location.back();
  }



}
